/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
         var stickyOffset = $('.primary-header').outerHeight();
         var is_stuck = false;

        function check_top_nav() {
          var sticky = $('.primary-header'),
            scroll = $(document).scrollTop();

          if (scroll > stickyOffset) {
            if(is_stuck){
              return;
            }

            var sticky_anim = gsap.timeline();
            sticky_anim.to('.primary-header', {
              yPercent: -100,
              duration: 0.4
            });
            sticky_anim.call(function() {
              $('.primary-header').addClass('has-scrolled');
            });
            sticky_anim.to('.primary-header', {
              yPercent: 0,
              duration: 0.4,
              delay: 0.9
            });
            is_stuck = true;
          } else {
            is_stuck = false;
            var normal_anim = gsap.timeline();
            normal_anim.to('.primary-header', {
              yPercent: -100,
              duration: 0.4
            });
            normal_anim.call(function() {
              $('.primary-header').removeClass('has-scrolled');
            });
            normal_anim.to('.primary-header', {
              yPercent: 0,
              duration: 0.4,
              delay: 0.4
            });

          }
        }

        // All Lazyload
        window.lazySizesConfig = window.lazySizesConfig || {};
        lazySizesConfig.loadMode = 3; 
        gsap.registerPlugin(ScrollTrigger);

        check_top_nav();

        $(window).scroll(function(){
           check_top_nav();
        });

        // Mobile Nav
        var open_tl = gsap.timeline({ paused: true, reversed: true,onStart:function(){ $('body').addClass('nav-open'); },onReverseComplete:function(){ $('body').removeClass('nav-open'); } });
        open_tl.to('.pop-nav', {
                duration: 0.4,
                startAt: {height:'65px'},
                height: "100%", 
                ease: Power2.easeInOut,
                force3D:true
             });

        open_tl.to('.pop-nav li', {
                delay: 0.3,
                duration: 1.05,
                stagger: 0.25,
                startAt: {y:'50%',opacity: 0},
                ease: Quint.easeOut,
                opacity: 1,
                y: '0%'
            });


        // Primary Nav
         var $hamburger = $(".trigger-mobile-nav");
          $hamburger.on("click", function(e) {
            e.preventDefault();
            if(open_tl.reversed()){ open_tl.timeScale(1); open_tl.play(); }else{ open_tl.timeScale(1.9); open_tl.reverse(); }
            $hamburger.toggleClass("is-active");
      
        });

          // https://tgomilar.github.io/paroller.js/#usage @todo
          var rellax = new Rellax('.rs-parallax,.header-slideshow .owl-nav');

        // Homepage slideshow
        if ($('.header-slideshow').length > 0) {
            $('.header-slideshow').owlCarousel({
              items:1,
              loop: true,
              nav: true,
              dots: false,
              navText: ['<span class="sr-only">Next Slide</span><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"></path></svg>','<span class="sr-only">Previous Slide</span><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg>']
            });
        }

        // Full Width Slideshow
         if ($('.full-width-slideshow-inner.owl-carousel').length > 0) {

          var stagePadding = parseInt(jQuery('.full-width-slideshow-inner').outerWidth() * 0.28);

            $('.full-width-slideshow-inner').owlCarousel({
              items:1,
              loop: true,
              center: true,
              autoplay: true,
              margin: 40,
              nav: true,
              dots: false,
              navText: ['<span class="sr-only">Next Slide</span><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"></path></svg>','<span class="sr-only">Previous Slide</span><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg>'],
              responsive:{
                  0:{
                      stagePadding: 40,
                      margin: 20
                  },
                  768:{
                      stagePadding: stagePadding
                  }
                }
            });
        }


        // To Top Button
        function totop_button(a) {
          var b = $(".back-to-top");
          b.removeClass("off on");
          if (a === "on") {
            b.addClass("on");
          } else {
            b.addClass("off");
          }
        }

        function backButtonShowHide() {
          $(window).scroll(function() {
            var b = $(this).scrollTop();
            var c = $(this).height();
            var d;
            if (b > 0) {
              d = b + c / 2;
            } else {
              d = 1;
            }
            if (d < 1e3) {
              totop_button("off");
            } else {
              totop_button("on");
            }
          });
        }

        function backToTop() {
          $(document).on('click', '.back-to-top', function(e) {
            e.preventDefault();

            $('body,html').animate({
              scrollTop: 0
            }, $(window).scrollTop() / 6, 'linear');
          });
        }

        // Grid Module

        var portfolio_width;

        function resizeMasonry(container) {
          var $window = jQuery(window);

          var largeItemHeight = container.find('.single-grid.col-12.col-md-6:first img').height();
          var largeWidthItemHeight = container.find('.single-grid.col-12.col-md-6:first img').height();

          largeItemHeight += 30;
          container.find('.single-grid.col-12.col-md-6:first img').css('height', largeWidthItemHeight);
          container.find('.single-grid.col-12.col-md-6:first img').css('height', largeItemHeight);
        }

        function init_grid_module_masonry() {
          if ($('.available-homes-grid .masonry-wrap').length) {

            $('.available-homes-grid .masonry-wrap').each(function() {
              var $window = jQuery(window);
              var $this = $(this);


              var $isotope = $this.isotope({
                itemSelector: '.available-homes-grid .masonry-wrap .single-grid',
                layoutMode: 'masonry',
                percentPosition: true,
                masonry: {
                  columnWidth: '.grid-sizer'
                }
              });

              // @todo- https://codepen.io/aFarkas/pen/VYGvde
              $isotope[0].addEventListener('load', (function() {
                var runs;
                var update = function() {
                  $isotope.isotope('layout');
                  runs = false;
                };
                return function() {
                  if (!runs) {
                    runs = true;
                    setTimeout(update, 33);
                  }
                };
              }()), true);

              $window.resize(function() {
                resizeMasonry($this);
              });

              resizeMasonry($this);

            });

          }
        }
        // Init 
        backButtonShowHide();
        backToTop();
        init_grid_module_masonry();

        // Init Animation Ins  
        var $window = jQuery(window);

        // Properties

         if ($('.homes-container-page').length) {
          var $homes_container = $('.homes-container-page');
          var homes_in_title = $homes_container.find('.col');
          var homes_in_img = $homes_container.find('.single-home');

          var ANIM_homes_container = gsap.timeline({});
          ANIM_homes_container.from(homes_in_title, {
            x: 80,
            duration: 0.7,
            autoAlpha: 0
          });
          ANIM_homes_container.from(homes_in_img, {
            y: 120,
            duration: 0.6,
            autoAlpha: 0,
            stagger: 0.15,
          });

          ScrollTrigger.create({
            trigger: $homes_container,
            start: "top 60%",
            animation: ANIM_homes_container,
            toggleActions: 'play none play none'
          });

         }


        if ($('.team-module').length) {
          var $team_member_mod = $('.team-module');
          var team_mod_in_title = $team_member_mod.find('.section-heading');
          var team_mod_in_img = $team_member_mod.find('.single-team-member');

          var ANIM_team_mod = gsap.timeline({});
          ANIM_team_mod.from(team_mod_in_title, {
            x: 80,
            duration: 0.7,
            autoAlpha: 0
          });
          ANIM_team_mod.from(team_mod_in_img, {
            y: 120,
            duration: 0.6,
            autoAlpha: 0,
            stagger: 0.15,
          });

          ScrollTrigger.create({
            trigger: $team_member_mod,
            start: "top 60%",
            animation: ANIM_team_mod,
            toggleActions: 'play none play none'
          });

        }

        if ($('.text-image-overlap-module').length) {
          var $this_image_overlap_mod = $('.text-image-overlap-module');
          var mod_in_title = $this_image_overlap_mod.find('.heading');
          var mod_in_img = $this_image_overlap_mod.find('.image-holder');
          var mod_in_content = $this_image_overlap_mod.find('.module-description');

          var ANIM_image_overlap_mod = gsap.timeline({});
          ANIM_image_overlap_mod.from(mod_in_title, {
            x: -80,
            duration: 0.7,
            autoAlpha: 0
          });
          ANIM_image_overlap_mod.from(mod_in_img, {
            y: 120,
            duration: 0.6,
            autoAlpha: 0,
            stagger: 0.15,
          });
          ANIM_image_overlap_mod.from(mod_in_content, {
            y: 120,
            duration: 0.6,
            autoAlpha: 0,
            stagger: 0.15,
          });

          ScrollTrigger.create({
            trigger: $this_image_overlap_mod,
            start: "top 60%",
            animation: ANIM_image_overlap_mod,
            toggleActions: 'play none play none'
          });

        }   

        if ($('.available-homes-grid').length) {
            var $this_grid = $('.available-homes-grid');
            var img_in_title = $this_grid.find('.module-heading');
            var img_in_hg = $this_grid.find('.single-grid');

            var ANIM_home_mod = gsap.timeline({});
            ANIM_home_mod.from(img_in_title, {
              x:-20,
              duration:0.7,
              autoAlpha: 0
            });
            ANIM_home_mod.from(img_in_hg, {
              y: 120,
              duration: 0.6,
              autoAlpha: 0,
              stagger: 0.15,
            });

            ScrollTrigger.create({
              trigger: '.available-homes-grid',
              start: "top 60%",
              animation: ANIM_home_mod,
              toggleActions: 'play none play none'
            });
          
        }

        if ($('.full-width-slideshow').length) {
          $('.full-width-slideshow').each(function() {
            var $this = $(this);
            var img_in_slideshow = $this.find('.full-width-slideshow-inner');

            var ANIM_slideshow = gsap.timeline({});
            ANIM_slideshow.from(img_in_slideshow, {
              y: 120,
              duration: 0.7,
              autoAlpha: 0
            });

            ScrollTrigger.create({
              trigger: $this,
              start: "top 40%",
              animation: ANIM_slideshow,
              toggleActions: 'play none play none',
              onEnter: function(e){ console.log('enter ss'); }
            });
          });
        }




        if($('.image-text-module.img-a-right').length){
         $('.image-text-module.img-a-right').each(function() {
              var $this = $(this);
              var img_in = $this.find('.first-image-row');
              var content_in = $this.find('.second-details-row');

              var ANIM_img_right =  gsap.timeline({});
              ANIM_img_right.from(img_in,{y:120,duration:0.5,autoAlpha:0});
              ANIM_img_right.from(content_in,{x:220,duration:0.5,autoAlpha:0});

               ScrollTrigger.create({
                trigger: $this,
                top: 500,
                start: "top 60%",
                animation:  ANIM_img_right,
                toggleActions: 'play none play none'
               });
            });
       }

        if ($('.image-text-module.img-a-left').length) {
            $('.image-text-module.img-a-left').each(function() {
              var $this = $(this);
              var img_in = $this.find('.first-image-row');
              var content_in = $this.find('.second-details-row');

              var ANIM_img_left =  gsap.timeline({});
              ANIM_img_left.from(img_in,{y:120,duration:0.5,autoAlpha:0});
              ANIM_img_left.from(content_in,{x:-220,duration:0.5,autoAlpha:0});

               ScrollTrigger.create({
                trigger: $this,
                top: 200,
                start: "top 60%",
                animation:  ANIM_img_left,
                 toggleActions: 'play none play none'
               });
            });
          }


           if ($('.image-text-module.img-a-left').length) {
            $('.image-text-module.img-a-left').each(function() {
              var $this = $(this);
              var module_heading = $this.find('.section-heading');
              var img_in = $this.find('.image-holder');
              var content_in = $this.find('.row-details');

              var ANIM_text_row_left = gsap.timeline({});
              ANIM_text_row_left.from(module_heading,{x:-90,autoAlpha:0,duration:0.7});
              ANIM_text_row_left.from(img_in,{xPercent:-40,duration:0.6,autoAlpha:0});
              ANIM_text_row_left.from(content_in,{y:110,duration:0.5,autoAlpha:0});

               ScrollTrigger.create({
                trigger: $this,
                top: 500,
                start: "top 60%",
                animation:  ANIM_text_row_left,
                 toggleActions: 'play none play none'
               });
            });
          }

           if ($('.image-text-row.pos-right').length) {
            $('.image-text-row.pos-right').each(function() {
              var $this = $(this);
              var module_heading = $this.find('.section-heading');
              var img_in = $this.find('.image-holder');
              var content_in = $this.find('.row-details');

              var ANIM_text_row_left = gsap.timeline({});
              ANIM_text_row_left.from(module_heading,{x:-90,autoAlpha:0,duration:0.7});
              ANIM_text_row_left.from(img_in,{xPercent:40,duration:0.6,autoAlpha:0});
              ANIM_text_row_left.from(content_in,{y:110,duration:0.5,autoAlpha:0});

               ScrollTrigger.create({
                trigger: $this,
                top: 500,
                start: "top 60%",
                animation:  ANIM_text_row_left,
               toggleActions: 'play none play none'
               });
            });
          }

          if ($('.image-text-row.pos-left').length) {
            $('.image-text-row.pos-left').each(function() {
              var $this = $(this);
              var module_heading = $this.find('.section-heading');
              var img_in = $this.find('.image-holder');
              var content_in = $this.find('.row-details');

              var ANIM_text_row_left = gsap.timeline({});
              ANIM_text_row_left.from(module_heading,{x:-90,autoAlpha:0,duration:0.7});
              ANIM_text_row_left.from(img_in,{xPercent:40,duration:0.6,autoAlpha:0});
              ANIM_text_row_left.from(content_in,{y:110,duration:0.5,autoAlpha:0});

               ScrollTrigger.create({
                trigger: $this,
                top: 500,
                start: "top 60%",
                animation:  ANIM_text_row_left,
               toggleActions: 'play none play none'
               });
            });
          }
      

        document.addEventListener('lazyloaded', function(e){
          ScrollTrigger.refresh();
        });


        // 
        $( window ).resize(function() {
          rellax.refresh();
          stickyOffset = $('.primary-header').outerHeight();
        }); 
        

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
